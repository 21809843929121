/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { jsx, Box, Flex, useThemeUI } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/seo/seo';
import Layout from '../components/Layout';

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const image = post.frontmatter.social
    ? post.frontmatter.social.childImageSharp.resize
    : null;
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        // featured={image}
        pathname={location.pathname}
        description={post.frontmatter.description || post.excerpt}
      />
      <div sx={{ mt: [3, 0] }}>
        <Link
          to="/blog"
          sx={{
            textDecoration: 'none',
            color: 'primary',
            textTransform: 'lowercase',
          }}
        >
          {`<- All Posts`}
        </Link>
      </div>
      <article sx={{ marginTop: 4 }}>
        <header>
          <h1 sx={{ variant: 'styles.h1' }}>{post.frontmatter.title}</h1>
          <p sx={{ variant: 'styles.blogDate' }}>{post.frontmatter.date}</p>
          <GatsbyImage
            image={post.frontmatter.image.childImageSharp.gatsbyImageData}
            sx={{
              boxShadow: '16px 16px 0 0',
              color: 'primary',
              height: 500,
              width: ['100%', 500],
              marginBottom: 5,
            }}
            alt={post.frontmatter.alt}
          />
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>

      {/* <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        alt
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(
              maxWidth: 400
              maxHeight: 400
              transformOptions: { grayscale: true }
              layout: FLUID
            )
          }
        }
        social: image {
          childImageSharp {
            resize(width: 1200, height: 630) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
